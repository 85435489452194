<template>
  <div>
    <a-modal
      v-model="isShow"
      width="800"
      centered
      :closable="false"
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="订购产品"
      @ok="handleSubmit"
    >
      <template slot="footer">
        <a-button
          class="cancel-btn"
          @click="closeModal"
        >
          取消
        </a-button>

        <a-button
          class="submit-btn"
          @click="handleSubmit"
        >
          提交
        </a-button>
      </template>

      <div class="product-box">
        <div class="custom-flex">
          <img
            v-show="data.images && data.images.length > 0"
            class="product-img"
            alt="img"
            v-for="(item, index) in data.images"
            :key="index"
            :src="item.url"
          >
          <img
            v-show="!data.images || data.images.length === 0"
            class="product-img"
            alt="img"
            src="/temp/no_picture.png"
          >

          <div class="product-content">
            <div class="product-name">{{ data.name }}</div>
            <div class="product-model">型号：{{ data.product_model }}</div>
            <div class="product-remark">介绍：{{ data.remark }}</div>
          </div>
        </div>
        <a-divider />

        <div class="title">项目结算</div>
        <a-form
          class="custom-compact-form"
          :form="form"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 19 }"
          @submit="handleSubmit"
        >
          <a-form-item label="规格">
            <a-radio-group
              button-style="solid"
              @change="handleChangeSpecification"
              v-decorator="['specification_id', { initialValue: defaultSpecificationId }]"
            >
              <a-radio-button
                v-for="specification in specifications"
                :key="specification.id"
                :value="specification.id"
              >
                {{ specification.name }}
              </a-radio-button>
            </a-radio-group>
          </a-form-item>

          <a-form-item label="数量">
            <div class="specification-count">
              <a-input-number
                :min="1"
                :max="max"
                :step="1"
                :precision="0"
                @change="changeSpecificationCount"
                v-decorator="['specificationCount', {
                  initialValue: 1,
                  rules: []
                }]"
              />
            </div>
          </a-form-item>

          <a-form-item label="单价">
            <div class="product-price">
              {{ specificationPriceDisplay }}
            </div>
          </a-form-item>

          <a-form-item label="应付">
            <div class="product-total-fee">
              ￥ {{ totalFee | formatCurrency }}
            </div>
          </a-form-item>

          <div v-if="data.product_type_slug === 'wreath'">
            <div
              v-for="(count, index) in specificationCount"
              :key="index"
            >
              <a-divider />

              <div class="title">挽联信息</div>
              <a-form-item label="逝者名称">
                <a-input
                  v-decorator="[`elegy_arr[${index}].death_name`, {
                    initialValue: data.death_name,
                    rules: [
                      { max: 100, message: '最多100个字符' },
                    ]
                  }]"
                />
              </a-form-item>

              <a-form-item label="逝者性别">
                <a-radio-group
                  @change="(e) => changeSex(e, index)"
                  v-decorator="[`elegy_arr[${index}].sex`, {
                    initialValue: data.death_sex,
                    rules: [{ message: '请选择逝者性别' }]
                  }]"
                >
                  <a-radio value="male">男</a-radio>
                  <a-radio value="female">女</a-radio>
                </a-radio-group>
              </a-form-item>

              <a-form-item label="与逝者关系">
                <a-select
                  @change="(e) => changeRelation(e, index)"
                  v-decorator="[`elegy_arr[${index}].relation`, {
                    rules: [{ max: 100, message: '最多100个字符' }]
                  }]"
                >
                  <a-select-option
                    v-for="relation in relationOptions"
                    :key="relation.name"
                    :value="relation.value"
                  >
                    {{ relation.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item label="称呼逝者">
                <a-select
                  @focus="fetchCallDeathOptions(index)"
                  @change="(e) => handleChangeCallDeath(e, index)"
                  v-decorator="[`elegy_arr[${index}].call_death`, {
                    rules: [{ max: 100, message: '最多100个字符' }]
                  }]"
                  mode="combobox"
                >
                  <a-select-option
                    v-for="callDeath in currentCallDeathOptions"
                    :key="callDeath.name"
                    :value="callDeath.value"
                  >
                    {{ callDeath.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="亲属方">
                <a-radio-group
                  v-decorator="[`elegy_arr[${index}].family`, {
                    rules: [{required: false, message: '请选择亲属方' }]
                  }]"
                >
                  <a-radio value="male">男方</a-radio>
                  <a-radio value="female">女方</a-radio>
                </a-radio-group>
              </a-form-item>
              <a-row :gutter="24">
                <a-col span="12">
                  <a-form-item label="敬赠人(男)" :label-col="{ span: 10 }" :wrapper-col="{ span: 24 }">
                    <a-input
                      v-decorator="[`elegy_arr[${index}].male_giver_name`, {
                        rules: [
                          { max: 10, message: '最多10个字符' },
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>
                <a-col span="12">
                  <a-form-item label="敬赠人(女)" :label-col="{ span: 10 }" :wrapper-col="{ span: 24 }">
                    <a-input
                      v-decorator="[`elegy_arr[${index}].female_giver_name`, {
                        rules: [
                          { max: 10, message: '最多10个字符' },
                        ]
                      }]"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
              <a-form-item :wrapper-col="{ span: 24 }">
                <a-radio-group
                  v-decorator="[`elegy_arr[${index}].with_family_type`]"
                  @change="(e) => handleChangeFamilyType(e, index)"
                >
                  <a-radio value="with_whole_family">携全家</a-radio>
                  <a-radio value="with_son">携子</a-radio>
                  <a-radio value="with_daughter">携女</a-radio>
                  <a-radio value="with_son_and_daughter">率子女</a-radio>
                </a-radio-group>
              </a-form-item>
              <a-form-item :wrapper-col="{ span: 24 }" v-if="isShowFamilyNameArr[index]">
                <a-input
                  placeholder="请输入家属姓名，多个姓名之间以逗号相隔"
                  v-decorator="[`elegy_arr[${index}].family_name`, {
                    rules: [
                      { max: 100, message: '最多100个字符' },
                    ]
                  }]"
                />
              </a-form-item>
              <a-form-item label="挽联内容">
                <a-select
                  @change="(e) => handleChangeElegyContent(e, index)"
                  v-decorator="[`elegy_arr[${index}].elegy_content`]"
                >
                  <a-select-option
                    v-for="elegyContent in elegyContentOptions"
                    :key="elegyContent.name"
                    :value="elegyContent.value"
                  >
                    {{ elegyContent.name }}
                  </a-select-option>
                </a-select>
                <span style="color: white;">{{ elegyContentArr[index] }}</span>
              </a-form-item>
            </div>
          </div>
        </a-form>
        <span class="tips">(此处订购产品均加入逝者对应的订单中，最后统一进行账单结算)</span>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { callDeathOptions } from '@/api/common'
import { numberRoundTwo } from '@/utils/math'
import { findSubscribeProductForm, subscribeProduct } from '@/api/product'

export default {
  name: 'SubscribeProduct',
  props: {
    id: {
      type: Number,
      required: true
    },
    serviceOrderId: {
      type: Number,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    this.addCount = this.$lodash.debounce(this.addCount, 400)
    this.reduceCount = this.$lodash.debounce(this.reduceCount, 400)
    return {
      form: this.$form.createForm(this, { name: 'subscribe_product' }),
      submitting: false,
      data: {},
      specifications: [],
      specificationCount: 1,
      defaultSpecificationId: 0,
      specificationPrice: undefined,
      specificationPriceDisplay: '',
      isShowFamilyNameArr: [false],
      gearPriceIndex: 1,
      elegyContentArr: [''],
      currentCallDeathOptions: [],
      max: 99
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },

    totalFee() {
      return numberRoundTwo(this.specificationPrice * this.specificationCount)
    },

    relationOptions() {
      return [
        {
          value: 'grandparents',
          name: '祖父/祖母'
        },
        {
          value: 'parents',
          name: '父亲/母亲'
        },
        {
          value: 'father_brothers_and_sisters',
          name: '叔叔/姑姑'
        },
        {
          value: 'mother_brothers_and_sisters',
          name: '舅舅/姨母'
        },
        {
          value: 'junior',
          name: '小辈'
        },
        {
          value: 'brothers_and_sisters',
          name: '兄弟姐妹'
        },
        {
          value: 'other',
          name: '其他关系'
        }
      ]
    },

    elegyContentOptions() {
      return [
        { name: '千古', value: '千古' },
        { name: '逝世', value: '逝世' },
        { name: '仙逝', value: '仙逝' },
        { name: '安息主怀', value: '安息主怀' }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findSubscribeProductForm(this.id, { service_order_id: this.serviceOrderId }).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.specifications = res.data.specifications
          if (this.specifications && this.specifications.length > 0) {
            this.defaultSpecificationId = this.specifications[0].id
            this.specificationPrice = this.specifications[0].price
            this.specificationPriceDisplay = this.specifications[0].price_display
          }
          if (this.data.product_type_slug === 'wreath') {
            this.max = 99
          } else {
            this.max = 500
          }
        }
      })
    },

    fetchCallDeathOptions(index) {
      const sex = this.form.getFieldValue(`elegy_arr[${index}].sex`)
      const relation = this.form.getFieldValue(`elegy_arr[${index}].relation`)
      this.currentCallDeathOptions = callDeathOptions(sex, relation)
    },

    changeSex(e, index) {
      this.form.setFieldsValue({ [`elegy_arr[${index}].call_death`]: undefined })
      // this.form.setFieldsValue({ [`elegy_arr[${index}].elegy_content`]: undefined })
      this.elegyContentArr[index] = ''
    },

    changeRelation(e, index) {
      this.form.setFieldsValue({ [`elegy_arr[${index}].call_death`]: undefined })
      // this.form.setFieldsValue({ [`elegy_arr[${index}].elegy_content`]: undefined })
      this.elegyContentArr[index] = ''
    },

    handleChangeFamilyType(e, index) {
      this.isShowFamilyNameArr[index] = false
      if (['with_daughter', 'with_son'].includes(e.target.value)) {
        this.isShowFamilyNameArr[index] = true
      }
    },
    handleChangeCallDeath(value, index) {
      const elegyContent = this.form.getFieldValue(`elegy_arr[${index}].elegy_content`)
      if (elegyContent) {
        this.elegyContentArr[index] = `沉痛悼念${value || ''}${elegyContent}`
      }
    },

    handleChangeElegyContent(value, index) {
      this.elegyContentArr[index] = ''
      if (value) {
        const callDeath = this.form.getFieldValue(`elegy_arr[${index}].call_death`)
        this.elegyContentArr[index] = `沉痛悼念${callDeath || ''}${value}`
      }
    },

    handleChangeSpecification(e) {
      for (const specification of this.specifications) {
        if (specification.id === e.target.value) {
          this.specificationPrice = specification.price
          this.specificationPriceDisplay = specification.price_display
        }
      }
    },

    reduceCount() {
      if (this.specificationCount > 1) {
        this.specificationCount -= 1
        this.isShowFamilyNameArr.splice(this.isShowFamilyNameArr.length - 1, 1)
        this.elegyContentArr.splice(this.elegyContentArr.length - 1, 1)
      }
    },

    addCount() {
      this.specificationCount += 1
      this.isShowFamilyNameArr.push(false)
      this.elegyContentArr.push('')
    },

    changeSpecificationCount(val) {
      if (this.data.product_type_slug === 'wreath') {
        if (val > this.specificationCount) {
          const count = val - this.specificationCount
          for (let i = 0; i < count; i++) {
            this.isShowFamilyNameArr.push(false)
            this.elegyContentArr.push('')
          }
        } else if (this.specificationCount > val) {
          const count = this.specificationCount - val
          for (let i = 0; i < count; i++) {
            this.isShowFamilyNameArr.splice(this.isShowFamilyNameArr.length - 1, 1)
            this.elegyContentArr.splice(this.elegyContentArr.length - 1, 1)
          }
        }
      }
      this.specificationCount = val
    },

    closeModal() {
      this.isShow = false
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            count: this.specificationCount,
            service_order_id: this.serviceOrderId
          }
          subscribeProduct(this.id, data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .ant-modal-content {
  background-color: #2a4c39;
}

/deep/ .ant-modal-header {
  border-bottom: none !important;
  background-color: #2a4c39 !important;

  .ant-modal-title {
    color: @white !important;
  }
}

/deep/ .ant-modal-footer {
  border-top: none;
  text-align: center;
  background-color: #2a4c39 !important;
}

/deep/ .ant-radio-group {
  .ant-radio-button-wrapper {
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 15px;
  }

  .ant-radio-button-wrapper-checked {
    color: rgba(0, 0, 0, 65%);
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    height: 0;
  }
}

.product-box {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 1000px;
}

.product-img {
  width: 160px;
  height: 120px;
  border-radius: 5px;
}

.product-content {
  margin-left: 20px;

  .product-name {
    font-size: 16px;
    color: @white;
  }

  .product-model {
    color: #848484;
  }

  .product-remark {
    color: #848484;
  }
}

.title {
  margin-bottom: 10px;
  color: #ffd794ff;
}

.specification-count {
  color: @white;

  img {
    cursor: pointer;
    width: 15px;
  }

  span {
    margin: 0 10px;
  }
}

.product-price {
  color: @white;
}

.product-total-fee {
  color: #ffd794ff;
}

.tips {
  color: #848484;
}

.cancel-btn {
  width: 150px;
  border: none;
  color: @white;
  background-color: #37543e;
}

.submit-btn {
  width: 150px;
  border: none;
  background-color: #ffd794ff;
}

/deep/ .submit-btn.ant-btn:hover {
  color: rgba(0, 0, 0, 65%);
}

/deep/ .submit-btn.ant-btn:focus {
  color: rgba(0, 0, 0, 65%);
}

/deep/ .ant-divider-horizontal {
  background-color: #848484;
}
</style>

