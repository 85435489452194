export function callDeathOptions(sex, relation) {
  switch (sex) {
    case 'male':
      switch (relation) {
        case 'grandparents':
          return [
            { value: '爷爷', name: '爷爷' },
            { value: '外公', name: '外公' },
            { value: '干爷爷', name: '干爷爷' },
            { value: '曾祖父', name: '曾祖父' },
            { value: '曾外祖父', name: '曾外祖父' },
            { value: '舅爷爷', name: '舅爷爷' },
            { value: '姨爷爷', name: '姨爷爷' },
            { value: '小爷爷', name: '小爷爷' }
          ]
        case 'parents':
          return [
            { value: '孝父', name: '孝父' },
            { value: '亲家公', name: '亲家公' },
            { value: '干爸', name: '干爸' }
          ]
        case 'father_brothers_and_sisters':
          return [
            { value: '伯父', name: '伯父' },
            { value: '叔叔', name: '叔叔' },
            { value: '姑父', name: '姑父' }
          ]
        case 'mother_brothers_and_sisters':
          return [
            { value: '舅舅', name: '舅舅' },
            { value: '姨夫', name: '姨夫' },
            { value: '姨伯伯', name: '姨伯伯' }
          ]
        case 'junior':
          return [
            { value: '内侄', name: '内侄' },
            { value: '内侄婿', name: '内侄婿' },
            { value: '侄子', name: '侄子' },
            { value: '侄婿', name: '侄婿' },
            { value: '外甥', name: '外甥' },
            { value: '外甥婿', name: '外甥婿' },
            { value: '姨侄', name: '姨侄' },
            { value: '姨侄婿', name: '姨侄婿' },
            { value: '外孙', name: '外孙' },
            { value: '外孙婿', name: '外孙婿' },
            { value: '侄孙', name: '侄孙' },
            { value: '干孙子', name: '干孙子' },
            { value: '曾孙', name: '曾孙' },
            { value: '曾孙婿', name: '曾孙婿' },
            { value: '曾外孙', name: '曾外孙' },
            { value: '曾外孙婿', name: '曾外孙婿' },
            { value: '干孙婿', name: '干孙婿' }
          ]
        case 'brothers_and_sisters':
          return [
            { value: '哥哥', name: '哥哥' },
            { value: '弟弟', name: '弟弟' },
            { value: '妹夫', name: '妹夫' },
            { value: '姐夫', name: '姐夫' },
            { value: '堂姐夫', name: '堂姐夫' },
            { value: '堂妹夫', name: '堂妹夫' },
            { value: '表哥', name: '表哥' },
            { value: '表弟', name: '表弟' },
            { value: '姨妹夫', name: '姨妹夫' },
            { value: '姨哥', name: '姨哥' },
            { value: '姨弟', name: '姨弟' },
            { value: '妻弟', name: '妻弟' },
            { value: '妻妹夫', name: '妻妹夫' },
            { value: '干哥哥', name: '干哥哥' },
            { value: '干弟弟', name: '干弟弟' },
            { value: '表妹夫', name: '表妹夫' },
            { value: '表姐夫', name: '表姐夫' },
            { value: '干妹夫', name: '干妹夫' }
          ]
        case 'other':
          return [
            { value: '干亲家公', name: '干亲家公' },
            { value: '老先生', name: '老先生' },
            { value: '先生', name: '先生' },
            { value: '伯父', name: '伯父' },
            { value: '兄弟', name: '兄弟' }
          ]
        default:
          return []
      }
    case 'female':
      switch (relation) {
        case 'grandparents':
          return [
            { value: '奶奶', name: '奶奶' },
            { value: '外婆', name: '外婆' },
            { value: '干奶奶', name: '干奶奶' },
            { value: '曾祖母', name: '曾祖母' },
            { value: '曾外祖母', name: '曾外祖母' },
            { value: '舅奶奶', name: '舅奶奶' },
            { value: '姨奶奶', name: '姨奶奶' },
            { value: '小奶奶', name: '小奶奶' }
          ]
        case 'parents':
          return [
            { value: '孝母', name: '孝母' },
            { value: '亲家母', name: '亲家母' },
            { value: '干妈', name: '干妈' }
          ]
        case 'father_brothers_and_sisters':
          return [
            { value: '伯母', name: '伯母' },
            { value: '亲家母', name: '阿姨' },
            { value: '婶婶', name: '婶婶' },
            { value: '姑姑', name: '姑姑' },
            { value: '姑妈', name: '姑妈' }
          ]
        case 'mother_brothers_and_sisters':
          return [
            { value: '舅妈', name: '舅妈' },
            { value: '姨姨', name: '姨姨' },
            { value: '姨妈', name: '姨妈' }
          ]
        case 'junior':
          return [
            { value: '内侄媳', name: '内侄媳' },
            { value: '内侄女', name: '内侄女' },
            { value: '侄媳', name: '侄媳' },
            { value: '侄女', name: '侄女' },
            { value: '外甥媳', name: '外甥媳' },
            { value: '外甥女', name: '外甥女' },
            { value: '姨侄媳', name: '姨侄媳' },
            { value: '姨侄女', name: '姨侄女' },
            { value: '外孙媳', name: '外孙媳' },
            { value: '外孙女', name: '外孙女' },
            { value: '侄孙媳', name: '侄孙媳' },
            { value: '干孙媳', name: '干孙媳' },
            { value: '曾孙媳', name: '曾孙媳' },
            { value: '曾孙女', name: '曾孙女' },
            { value: '曾外孙媳', name: '曾外孙媳' },
            { value: '曾外孙女', name: '曾外孙女' },
            { value: '干孙女', name: '干孙女' }
          ]
        case 'brothers_and_sisters':
          return [
            { value: '嫂嫂', name: '嫂嫂' },
            { value: '弟媳', name: '弟媳' },
            { value: '妹妹', name: '妹妹' },
            { value: '姐姐', name: '姐姐' },
            { value: '堂姐', name: '堂姐' },
            { value: '堂妹', name: '堂妹' },
            { value: '表嫂', name: '表嫂' },
            { value: '表弟媳', name: '表弟媳' },
            { value: '姨妹', name: '姨妹' },
            { value: '姨嫂', name: '姨嫂' },
            { value: '姨弟媳', name: '姨弟媳' },
            { value: '妻弟媳', name: '妻弟媳' },
            { value: '妻妹', name: '妻妹' },
            { value: '干嫂嫂', name: '干嫂嫂' },
            { value: '干弟媳', name: '干弟媳' },
            { value: '表妹', name: '表妹' },
            { value: '表姐', name: '表姐' },
            { value: '干妹妹', name: '干妹妹' }
          ]
        case 'other':
          return [
            { value: '干亲家母', name: '干亲家母' },
            { value: '老太太', name: '老太太' },
            { value: '女士', name: '女士' },
            { value: '伯母', name: '伯母' },
            { value: '姊妹', name: '姊妹' }
          ]
        default:
          return []
      }
    default:
      return ''
  }
}
